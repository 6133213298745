require('./bootstrap');
window.$ = window.jQuery = require('jquery');
console.log('js On');

$( document ).ready(function() {
    $('#cookieOk').click(function() {
        console.log('cookie set');
        document.cookie = "cookie=1";
        $('#cookieMsg').css("visibility", "hidden");
    });
    
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    var cookie = getCookie("cookie");
    if(cookie==1){
        $('#cookieMsg').css("visibility", "hidden");
    }else{
        $('#cookieMsg').css("visibility", "visible");
    }
    
});




